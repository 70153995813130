import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { TreeView } from '@material-ui/lab'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import { FolderItem } from './folder-item'
import { MediaFoldersTreeData } from '../../types'
import { Box } from '@material-ui/core'
import { Loading } from './loading'

type Props = {
  selected?: number | null
  onSelect: (id: number) => void
  data: MediaFoldersTreeData
  isLoading: boolean
  onEdit: (id: number) => void
}

export const MediaFolders: FC<Props> = ({ selected, onSelect, data, isLoading, onEdit }) => {
  const [expanded, setExpanded] = useState<string[]>(['0'])

  const { tree, idToNodeMap } = data

  const expandHandler = useCallback((expandId: string) => {
    setExpanded((ids) => {
      if (ids.includes(expandId)) {
        return ids.filter((id) => id !== expandId)
      }

      return [...ids, expandId]
    })
  }, [])

  /**
   * Set initial expanded by selected path
   */
  const initialized = useRef(false)
  useEffect(() => {
    if (initialized.current || isLoading || !selected) return
    const selectedFolder = idToNodeMap[`/api/media_folders/${selected}`]
    setExpanded((prev) => {
      return [...prev, ...selectedFolder.path.map(String)]
    })
    initialized.current = true
  }, [data, selected, isLoading, idToNodeMap])

  if (isLoading) return <Loading />

  return (
    <Box py={1} maxHeight="inherit" height="inherit" overflow="auto">
      <TreeView
        defaultCollapseIcon={<ArrowDropDown />}
        defaultExpandIcon={<ArrowRight />}
        selected={typeof selected !== 'number' ? '' : selected.toString()}
        expanded={expanded}
      >
        {tree?.map((folder) => (
          <FolderItem
            key={folder.id}
            folder={folder}
            expanded={expanded}
            onEdit={onEdit}
            onExpand={expandHandler}
            onSelect={(id) => onSelect(+id)}
          />
        ))}
      </TreeView>
    </Box>
  )
}
