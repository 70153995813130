import { httpService } from 'core/data/services'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

type Params<Data = any> = Omit<UseQueryOptions<Data, unknown>, 'queryKey'> & {
  queryKey?: QueryKey
}

export const useGetOne = <Data = any>(resource: string, id: number, params: Params = {}) => {
  const { queryKey, ...queryOptions } = params

  return useQuery<unknown, unknown, Data>(
    [resource, id, queryKey],
    () => {
      return httpService.get<Data>(`/${resource}/${id}`).then((res) => res.data)
    },
    queryOptions
  )
}
