import { Box, Chip, CircularProgress, Typography } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { SidebarHeader } from '../sidebar-header'
import DropArea from 'common/components/drop-area'
import { useDropzone } from 'react-dropzone'
import { FileToUpload } from './entity'
import { useMutation } from 'react-query'
import { httpService } from 'core/data'
import { modifySvg } from './utils/modify-svg'

type Props = {
  defaultFolder: number | null
  onFileUpload: () => void
  onClose: () => void
}

export const UploadFiles: FC<Props> = ({ defaultFolder, onFileUpload, onClose }) => {
  const [files, setFiles] = useState<FileToUpload[]>([])

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => new FileToUpload(file))
    setFiles([...files, ...newFiles])
  }

  const dropzoneProps = useDropzone({ onDrop })

  return (
    <Box position="relative" overflow="auto" height="inherit">
      <SidebarHeader title="Add file" onClose={onClose} />
      <Box p={2}>
        <DropArea dropzoneProps={dropzoneProps} />
      </Box>
      <Box px={2} py={1} display="flex" flexDirection="column" style={{ gap: 16 }}>
        {files.map((file) => (
          <FileItem
            key={file.id}
            file={file}
            folder={defaultFolder}
            onFileUploadSuccess={onFileUpload}
          />
        ))}
      </Box>
    </Box>
  )
}

type FileItemProps = {
  file: FileToUpload
  folder: number | null
  onFileUploadSuccess: () => void
}

const FileItem: FC<FileItemProps> = ({ file, folder, onFileUploadSuccess }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  const mounted = useRef(false)

  const { isError, isSuccess, isLoading, mutate } = useMutation(
    async () => {
      const formData = new FormData()

      let previewBlob: File | Blob = file.file

      if (file.file.name.endsWith('.svg')) {
        const modifiedBlob = await modifySvg(file.file)
        previewBlob = modifiedBlob
        formData.append('file', modifiedBlob, file.file.name)
      } else {
        formData.append('file', file.file)
      }

      if (folder) {
        formData.append('folder', `/api/media_folders/${folder}`)
      }

      await httpService.post(`/media`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return URL.createObjectURL(previewBlob)
    },
    {
      retry: false,
      onSuccess: (data) => {
        onFileUploadSuccess()
        setPreviewUrl(data)
      },
    }
  )

  useEffect(() => {
    return () => {
      if (previewUrl) URL.revokeObjectURL(previewUrl)
    }
  }, [previewUrl])

  useEffect(() => {
    if (mounted.current) return
    mutate()
    mounted.current = true
  }, [mutate])

  return (
    <Box display="flex" justifyContent="space-between" alignContent="center">
      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Box width={40} height={40} borderRadius="borderRadius" overflow="hidden">
          {previewUrl && (
            <img
              src={previewUrl}
              alt=""
              width="100%"
              height="100%"
              style={{ objectFit: 'cover' }}
            />
          )}
        </Box>
        <Typography>{file.file.name}</Typography>
      </Box>
      {isError && <Chip label="Error" color="secondary" />}
      {isSuccess && <Chip label="Success" color="primary" />}
      {isLoading && <CircularProgress size="22px" />}
    </Box>
  )
}
