// Core
import React, { FC, useCallback } from 'react'
import { ListContext, useListController } from 'core/data'
import { useTranslation } from 'react-i18next'
// Components
import { Grid } from '@material-ui/core'
import { DialogTitle } from 'ui'
import { Pagination } from 'core/components'
import { Dialog, DialogContent } from '@material-ui/core'
import { Search, WidgetItem } from './components'
// Style
import useStyles from './widget-types-modal.styles'

type TProps = {
  opened: boolean
  onClose: () => void
  onAddWidget: (widgetType: any) => void
}

const WidgetTypesModal: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { opened, onClose, onAddWidget } = props

  const controller = useListController(
    {
      name: 'widget_types',
      hasCreate: false,
      hasEdit: false,
      hasList: false,
    },
    { filterChangeUrl: false }
  )

  const closeHandler = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog className={classes.root} maxWidth="md" fullWidth open={opened} onClose={closeHandler}>
      <ListContext.Provider value={controller}>
        <DialogTitle onClose={closeHandler}>{t('page-builder.widget.modal-title')}</DialogTitle>
        <div className={classes.searchWrap}>
          <Search />
        </div>

        <DialogContent>
          <Grid container spacing={2}>
            {controller.data?.map((widgetData) => (
              <WidgetItem onSelect={onAddWidget} data={widgetData} key={widgetData.id} />
            ))}
          </Grid>
        </DialogContent>
        <Pagination />
      </ListContext.Provider>
    </Dialog>
  )
}

export default WidgetTypesModal
