const readFileContent = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      event.target && resolve(event.target.result as string)
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsText(file)
  })
}

// Modify SVG file MIME type to "image/svg+xml"
export const modifySvg = async (file: File) => {
  const svgContent = await readFileContent(file)

  const isSvgXml = svgContent.includes('<?xml')
  const hasCorrectXmlns = svgContent.match(
    /<svg[^>]*\sxmlns=(["'])http:\/\/www\.w3\.org\/2000\/svg\1/
  )

  let modifiedSvgContent = svgContent

  // Add XML declaration if missing
  if (!isSvgXml) {
    modifiedSvgContent = `<?xml version="1.0" encoding="utf-8"?>\n${modifiedSvgContent}`
  }

  // Add correct xmlns if missing or incorrect
  if (!hasCorrectXmlns) {
    modifiedSvgContent =
      modifiedSvgContent.replace(
        /<svg([^>]*)\sxmlns=(["'])[^"']*\2/,
        '<svg$1 xmlns="http://www.w3.org/2000/svg"'
      ) || modifiedSvgContent.replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg"')
  }

  return new Blob([modifiedSvgContent], { type: 'image/svg+xml' })
}
