import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { purpleLight, purpleDark } = defaultThemeColors

const WidgetItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: 'none',
      padding: theme.spacing(3),
      width: '100%',
      height: '100%',
      backgroundColor: purpleLight,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.standard,
      }),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: purpleDark,
      },
    },
    defaultIcon: {
      fill: theme.palette.primary.main,
    },
    text: {
      marginTop: theme.spacing(1),
      overflowWrap: 'break-word',
    },
  })
)

export default WidgetItemStyles
