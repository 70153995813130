import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Media, MediaListItem } from '../types'
import { useGetMediaFolders } from '../hooks/use-get-media-folders'
import { formatFileSize } from '../utils'
import { Box, Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useQueryClient } from 'react-query'
import { MediaFolders } from '../components/media-folders'
import { UploadFiles } from '../components/upload-file'
import { FolderPreview } from '../components/folder-preview'
import { FilePreview } from '../components/file-preview'
import { GridColumn } from 'core/data/v2/data-grid/types'
import { DataGrid } from 'core/data/v2/data-grid'
import { useMediaManagerController } from './use-media-manager-controller'
import { Breadcrumbs } from '../components/breadcrumbs'
import { NameCell, LocationCell } from '../components/grid-columns'
import { BulkSelectedPanel } from '../components/bulk-selected-panel'
import { useMediaBulkActions, useMediaBulkActionsHelperStorage } from './use-media-bulk-actions'
import { BulkToolbar } from '../components/bulk-toolbar'

const QUERY_KEY = 'media-manager-grid'

type Props = {
  maxHeight: string
  onSelectMedia?: (media: Media) => void
  selectedMedia?: number | null
  saveLastSelectedFolder?: boolean
}

export const MediaManager: FC<Props> = ({
  maxHeight,
  onSelectMedia,
  selectedMedia: selectedMediaProp,
  saveLastSelectedFolder,
}) => {
  const queryClient = useQueryClient()

  const revalidateData = useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY)
  }, [queryClient])

  const {
    foldersData,
    foldersTreeData,
    isLoading,
    defaultFolder,
    refetchFolders,
    setLastSelectedFolder,
  } = useGetMediaFolders({ saveLastSelectedFolder })

  const { helperStorage, setHelperStorageData } = useMediaBulkActionsHelperStorage()

  const {
    selectedRows,
    isBulkSelection,
    bulkPanelOpen,
    selectedMedia,
    folderFilter,
    selectedFolder,
    folderPanelOpen,
    resetState,
    setFolderFilter,
    uploadFileOpen,
    selectMediaHandler,
    openFolderPanel,
    openUpload,
    search,
    setSearch,
    doubleClickHandler,
    rowsSelectionChangeHandler,
    toggleBulkPanel,
  } = useMediaManagerController({
    defaultFolder,
    defaultMedia: selectedMediaProp,
    onRowSelectionChange: setHelperStorageData,
  })

  const bulkActionSuccessHandler = useCallback(
    (action: 'delete' | 'copy' | 'move') => {
      if (action !== 'copy') {
        rowsSelectionChangeHandler([], {})
      }
      refetchFolders()
      return revalidateData()
    },
    [refetchFolders, revalidateData, rowsSelectionChangeHandler]
  )

  const { bulkDelete, bulkCopy, bulkMove, dndMoveHandler, FolderSelectModal } = useMediaBulkActions(
    {
      selected: selectedRows,
      helperStorage,
      selectedFolder: folderFilter!,
      onSuccess: bulkActionSuccessHandler,
      queryKey: QUERY_KEY,
      folders: foldersData || [],
    }
  )

  /**
   * Sync default value folder with folder filter value
   */
  useEffect(() => {
    setFolderFilter(defaultFolder)
  }, [defaultFolder, setFolderFilter])

  /**
   * Save last selected folder to storage
   */
  useEffect(() => {
    if (typeof folderFilter !== 'number') return
    setLastSelectedFolder(folderFilter)
  }, [folderFilter, setLastSelectedFolder])

  /**
   * Refetch folders data after folder filter change
   */
  useEffect(() => {
    refetchFolders()
  }, [folderFilter, refetchFolders])

  const rightSidebar = useMemo(() => {
    if (selectedMedia) {
      return (
        <FilePreview
          mediaId={selectedMedia}
          onClose={resetState}
          onCopy={bulkCopy}
          onMove={bulkMove}
          onDeleteSuccess={() => {
            resetState()
            revalidateData()
          }}
          onUpdateSuccess={() => {
            revalidateData()
          }}
        />
      )
    }

    if (uploadFileOpen && folderFilter) {
      return (
        <UploadFiles
          defaultFolder={folderFilter}
          onFileUpload={revalidateData}
          onClose={resetState}
        />
      )
    }

    if (folderPanelOpen && typeof folderFilter === 'number') {
      return (
        <FolderPreview
          defaultFolder={folderFilter}
          folderId={selectedFolder}
          onClose={resetState}
          onCopy={bulkCopy}
          onMove={bulkMove}
          onEditSuccess={() => {
            refetchFolders()
            revalidateData()
          }}
          onDeleteSuccess={() => {
            resetState()
            refetchFolders()
            setFolderFilter(defaultFolder)
            revalidateData()
          }}
        />
      )
    }

    if (bulkPanelOpen && isBulkSelection) {
      return (
        <BulkSelectedPanel
          onClose={() => toggleBulkPanel(false)}
          selectedCount={selectedRows.length}
          onRemove={bulkDelete}
          onCopy={bulkCopy}
          onMove={bulkMove}
        />
      )
    }

    return null
  }, [
    bulkCopy,
    bulkDelete,
    bulkMove,
    bulkPanelOpen,
    defaultFolder,
    folderFilter,
    folderPanelOpen,
    isBulkSelection,
    refetchFolders,
    resetState,
    revalidateData,
    selectedFolder,
    selectedMedia,
    selectedRows.length,
    setFolderFilter,
    toggleBulkPanel,
    uploadFileOpen,
  ])

  const gridColumns = useMemo(() => {
    let columns: GridColumn<MediaListItem>[] = [
      {
        field: 'name',
        type: 'custom',
        cellRenderer: ({ rowData }) => (
          <NameCell onFolderTitleClick={setFolderFilter} media={rowData} />
        ),
        width: 250,
      },
      {
        field: 'size',
        type: 'custom',
        cellRenderer: ({ rowData }) => formatFileSize(rowData.size),
        width: 90,
      },
      {
        field: 'createdBy',
        type: 'relation',
        title: 'Author',
        resource: 'users',
        titleField: (user) => `${user.firstName} ${user.lastName}`,
        sorting: {
          sortingField: 'createdBy.firstName',
        },
        width: 150,
      },
      { field: 'createdAt', type: 'date', title: 'Created at', width: 100 },
      { field: 'updatedAt', type: 'date', title: 'Updated at', width: 100 },
    ]

    if (search) {
      columns = columns.toSpliced(1, 0, {
        field: 'parent',
        type: 'custom',
        cellRenderer: ({ rowData }) => (
          <LocationCell media={rowData} foldersData={foldersData || []} />
        ),
        title: 'Location',
      })
    }

    return columns
  }, [search, setFolderFilter, foldersData])

  const memoFilters = useMemo(() => {
    return {
      folder: !folderFilter && search ? undefined : folderFilter || null,
    }
  }, [folderFilter, search])

  return (
    <>
      <DataGrid
        maxHeight={maxHeight}
        queryKey={QUERY_KEY}
        resource="media_advanceds"
        columns={gridColumns}
        filters={memoFilters}
        queryOptions={{ enabled: typeof folderFilter === 'number' }}
        selected={selectedRows}
        onRowSelect={selectMediaHandler}
        onRowSelectionChange={rowsSelectionChangeHandler}
        onDoubleClickSelect={doubleClickHandler}
        onSearchChange={setSearch}
        onDragEnd={dndMoveHandler}
        leftSidebar={
          <MediaFolders
            selected={folderFilter}
            onSelect={setFolderFilter}
            data={foldersTreeData || []}
            isLoading={isLoading}
            onEdit={(id) => openFolderPanel(id)}
          />
        }
        rightSidebar={rightSidebar}
        breadcrumbs={
          <Breadcrumbs
            folder={folderFilter || 0}
            foldersData={foldersData || []}
            onSelect={setFolderFilter}
          />
        }
        actionsHolder={
          <Box style={{ display: 'flex', gap: 16 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              onClick={() => openFolderPanel()}
              disabled={!folderFilter}
            >
              Add folder
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => openUpload()}
              disabled={!folderFilter}
            >
              Add file
            </Button>
          </Box>
        }
      />
      <BulkToolbar
        selected={selectedRows}
        onClose={resetState}
        onDelete={bulkDelete}
        onCopy={bulkCopy}
        onMove={bulkMove}
        selectedMedia={selectedMedia}
        onInsert={onSelectMedia}
        helperStorage={helperStorage}
      />
      {FolderSelectModal}
    </>
  )
}
