import { httpService } from 'core/data'
import { HydraResponse } from 'core/types'
import { useQuery } from 'react-query'
import { MediaFolder } from '../types'
import { buildTree } from '../utils'
import { useGetActiveSite } from 'modules/sites'
import { useCallback, useMemo } from 'react'

const SELECTED_FOLDER_KEY = 'media_manager_selected_folder'

const selectedFolderStorage = {
  setValue: (val: number | null | undefined) => {
    if (typeof val !== 'number') return
    sessionStorage.setItem(SELECTED_FOLDER_KEY, val.toString())
  },
  getValue: () => {
    const val = sessionStorage.getItem(SELECTED_FOLDER_KEY)
    return val ? +val : null
  },
}

type Params = {
  saveLastSelectedFolder?: boolean
}

export const useGetMediaFolders = (params: Params = {}) => {
  const { saveLastSelectedFolder } = params
  const activeSite = useGetActiveSite()

  const { data, isLoading, refetch } = useQuery(
    ['media-folders'],
    () => {
      return httpService
        .get<HydraResponse<MediaFolder>>('/media_folders')
        .then((res) => res.data['hydra:member'])
    },
    {
      select: (data) => {
        return {
          foldersData: data,
          foldersTreeData: buildTree(data),
        }
      },
    }
  )

  const defaultFolder = useMemo(() => {
    if (!data?.foldersTreeData) return null

    const storageValue = selectedFolderStorage.getValue()

    if (saveLastSelectedFolder && typeof storageValue === 'number') {
      return storageValue
    }

    const findFolder = data.foldersTreeData.tree[0].children.find(
      (folder) => folder.site === activeSite?.['@id']
    )

    return findFolder?.id ?? null
  }, [activeSite, data?.foldersTreeData, saveLastSelectedFolder])

  const setLastSelectedFolder = useCallback(
    (folder: number) => {
      if (!saveLastSelectedFolder) return
      selectedFolderStorage.setValue(folder)
    },
    [saveLastSelectedFolder]
  )

  return {
    foldersData: data?.foldersData,
    foldersTreeData: data?.foldersTreeData,
    isLoading,
    defaultFolder,
    refetchFolders: refetch,
    setLastSelectedFolder,
  }
}
