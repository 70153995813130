import { Box, Dialog, DialogProps, makeStyles } from '@material-ui/core'
import React, { FC, useCallback, useRef, useState } from 'react'
import { DialogTitle } from 'ui'
import { MediaManager } from '../media-manager'
import { Media } from '../types'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2, 2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
    height: `calc(100% - ${theme.spacing(4)}px)`,
    maxWidth: '100%',
  },
}))

type Props = {
  onSelect: (media: Media) => void
  selectedMedia?: number | null
} & Pick<DialogProps, 'open' | 'onClose'>

export const SelectMedia: FC<Props> = ({ open, onClose, onSelect, selectedMedia }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      classes={{ paper: classes.paper }}
      maxWidth={false}
    >
      <DialogTitle onClose={onClose}>Select media</DialogTitle>
      <Box bgcolor="rgba(244, 246, 248, 1)" p={2}>
        <MediaManager
          maxHeight="calc(100vh - 64px - 64px)"
          onSelectMedia={onSelect}
          selectedMedia={selectedMedia}
          saveLastSelectedFolder
        />
      </Box>
    </Dialog>
  )
}

type SelectMediaHandler = (media: Media) => void

type OpenSelectMediaHandlerProps = {
  handler?: SelectMediaHandler
  selectedMedia?: number
}

export type OpenSelectMediaFunc = (params: OpenSelectMediaHandlerProps) => void

export const useSelectMedia = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<number | null>(null)
  const handlerRef = useRef<SelectMediaHandler | null>(null)

  const closeHandler = useCallback(() => {
    setIsOpen(false)
    setSelected(null)
  }, [])

  const openSelectMedia = useCallback<OpenSelectMediaFunc>(({ handler, selectedMedia }) => {
    setIsOpen(true)
    if (selectedMedia) {
      setSelected(selectedMedia)
    }
    if (handler) {
      handlerRef.current = handler
    }
  }, [])

  const selectHandler = useCallback((media: Media) => {
    if (handlerRef.current) {
      handlerRef.current(media)
    }
    setIsOpen(false)
    setSelected(null)
  }, [])

  return {
    openSelectMedia,
    MediaManagerModal: (
      <SelectMedia
        open={isOpen}
        onClose={closeHandler}
        selectedMedia={selected}
        onSelect={selectHandler}
      />
    ),
  }
}
