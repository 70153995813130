// Core
import React, { FC, useCallback } from 'react'
import { Grid } from '@material-ui/core'
// Components
import { WidgetIcon } from 'modules/new-entity/components/page-builder/components'
// Style
import useStyles from './widget-item.styles'

type TProps = {
  data?: any
  onSelect: (widgetType: any) => void
}

const WidgetItem: FC<TProps> = (props) => {
  const { data, onSelect } = props

  const classes = useStyles()

  const clickHandler = useCallback(() => {
    onSelect(data)
  }, [data, onSelect])

  return (
    <Grid item xs={3}>
      <button type="button" className={classes.root} onClick={clickHandler}>
        <WidgetIcon icon={data.icon} defaultIconClassName={classes.defaultIcon} />
        <div className={classes.text}>{data.name}</div>
      </button>
    </Grid>
  )
}

export { WidgetItem }
