// Core
import React, { useState } from 'react'
import { Box, BoxProps } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
// Icons
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
// Hooks
import { useGetOne } from 'core/data/v2/hooks/use-get-one'
// Utils
import { getIdFromIri } from 'core/utils'
// Types
import { Media } from 'modules/media/types'

type Props = {
  icon: string
  className?: string
  skeletonClassName?: string
  defaultIconClassName?: string
} & BoxProps

const WidgetIcon: React.FC<Props> = (props) => {
  const { icon, className, skeletonClassName, defaultIconClassName, ...rest } = props

  const [imageLoadError, setImageLoadError] = useState(false)

  const { data: media, isLoading } = useGetOne<Media>('media', +getIdFromIri(icon), {
    enabled: Boolean(icon),
  })

  if (isLoading)
    return <Skeleton variant="circle" height={24} width={24} className={skeletonClassName} />

  if (!media || imageLoadError) return <ChromeReaderModeIcon className={defaultIconClassName} />

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      overflow="hidden"
      height="24px"
      width="24px"
      fontSize="24px"
      flexShrink={0}
      className={className}
      {...rest}
    >
      <img
        src={media.sourceUrl}
        alt={media.alt}
        onError={() => {
          setImageLoadError(true)
        }}
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  )
}

export default WidgetIcon
